<template>
    <div class="notification-display">
        <StackRouterHeaderBar :leftButtonHandler="save" />
        <div class="title m-b-16" v-html="$translate('NOTIFY_DISPLAY')" />
        <div class="content-container">
            <div class="content" v-for="content in allContents" :key="content.key">
                <div class="c-grey-06 f-12 f-medium" v-html="$translate(content.key)" />
                <div class="example">
                    <div class="top flex-center m-b-4">
                        <span class="c-black f-medium">{{
                            content.key === 'CHAT_MESSAGE_DISPLAY' ? chatMode.msg.name : formalMode.msg.name
                        }}</span>
                        <div class="ex-btn" v-html="`예시`" />
                    </div>
                    <div
                        class="bottom"
                        v-html="content.key === 'CHAT_MESSAGE_DISPLAY' ? chatMode.msg.content : formalMode.msg.content"
                    />
                </div>
                <div
                    @click="onClickItem(content.key, option)"
                    :key="option.id"
                    class="option"
                    v-for="option in content.options"
                >
                    <span class="f-16 c-black" v-html="option.text" />
                    <i class="material-icons flex-wrap" v-if="selected(content.key, option)">radio_button_checked</i>
                    <i class="material-icons flex-wrap" v-else>radio_button_unchecked</i>
                </div>
                <div class="hr" v-if="content.key === 'CHAT_MESSAGE_DISPLAY'" />
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'NotificationDisplayPage',
    data: () => ({
        chatMode: null,
        formalMode: null,
    }),
    mounted() {
        this.init()
        this.$registerBackHandler(this.save)
    },
    beforeDestroy() {
        this.$unregisterBackHandler(this.save)
    },
    methods: {
        init() {
            const { message_display: md, notify_display: nd } = this.$store.getters.me

            this.chatMode = this.allContents[0].options[md - 1]
            this.formalMode = this.allContents[1].options[nd - 1]
        },
        onClickItem(key, item) {
            if (key === 'CHAT_MESSAGE_DISPLAY') {
                this.chatMode = item
            } else {
                this.formalMode = item
            }
        },
        selected(key, option) {
            if (key === 'CHAT_MESSAGE_DISPLAY') {
                return option.id === this.chatMode.id
            } else {
                return option.id === this.formalMode.id
            }
        },
        async save() {
            const payload = {
                message_display: this.chatMode.id,
                notify_display: this.formalMode.id,
            }
            try {
                await userService.update(payload)
                this.$stackRouter.pop()
                this.$store.commit('setMe', { ...this.$store.getters.me, ...payload })
                this.$toast.success('UPDATED')
            } catch (e) {
                console.log(e)
            }
        },
    },
    computed: {
        allContents() {
            return [
                {
                    key: 'CHAT_MESSAGE_DISPLAY',
                    options: [
                        {
                            id: 1,
                            type: 'all',
                            text: '이름 + 메시지',
                            msg: {
                                name: '이지혁',
                                content: '이 분 어떠신가요?',
                            },
                        },
                        {
                            id: 2,
                            type: 'name',
                            text: '이름',
                            msg: {
                                name: '이지혁',
                                content: '새로운 메시지가 있습니다.',
                            },
                        },
                        {
                            id: 3,
                            type: 'none',
                            text: '표시 안함',
                            msg: {
                                name: '알림',
                                content: '새로운 메시지가 있습니다.',
                            },
                        },
                    ],
                },
                {
                    key: 'FORMAL_MESSAGE_DISPLAY',
                    options: [
                        {
                            id: 1,
                            type: 'all',
                            text: '제목 + 메시지',
                            msg: {
                                name: '소개팅 제안 도착',
                                content: '유블리(28세), 서울 강남구, 회사원',
                            },
                        },
                        {
                            id: 2,
                            type: 'name',
                            text: '제목',
                            msg: {
                                name: '소개팅 제안 도착',
                                content: '새로운 알림이 있습니다.',
                            },
                        },
                        {
                            id: 3,
                            type: 'none',
                            text: '표시 안함',
                            msg: {
                                name: '알림',
                                content: '새로운 알림이 있습니다.',
                            },
                        },
                    ],
                },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.notification-display {
    .title {
        @include f-medium;
        color: black;
        font-size: 24px;
        padding: 0 16px;
    }
    .content-container {
        padding: 16px;

        .example {
            padding: 12px 16px;
            background: $grey-01;
            border-radius: 12px;
            font-size: 14px;
            margin: 24px 0;

            .ex-btn {
                color: $grey-06;
                font-size: 12px;
                background: white;
                padding: 1px 8px;
                border-radius: 6px;
            }
        }

        .option {
            @include between;
            margin-bottom: 28px;

            i {
                color: $purple-primary;
            }
        }
    }
}
.hr {
    width: 100%;
    height: 1px;
    background: $grey-02;
    margin-top: 4px;
    margin-bottom: 32px;
}
</style>
